
import { isUrl, loadFile } from "../../utils/tools";
import { client } from '@/client';
import { defineComponent } from 'vue';
import { ElMessage } from "element-plus";
import { Plus } from "@element-plus/icons-vue";
import { ResDetail } from "@/shared/adminprotocols/user/PtlDetail";
import { ReqUpdate } from "@/shared/adminprotocols/user/PtlUpdate";
  export default defineComponent ({
    name: "admin-edit",
    components: {
      Plus,
    },
    data: () => {
      return {
        formLabelWidth:'150px',
        activeName: "first",
        activeIndex: "0",
        params: {} as ResDetail,
        paramsRules: {
          nickName: [
            { required: true, message: "请输入用户名", trigger: "blur" },
            {
              min: 2,
              max: 20,
              message: "2到20个字符",
              trigger: "blur",
            }
          ],
          password: [
            { required: false, message: "请输入密码", trigger: "blur" },
            {
              min: 6,
              max: 20,
              message: "6到20个字符",
              trigger: "blur",
            }
          ],
          payPassword: [
            { required: false, message: "请输入支付密码", trigger: "blur" },
            {
              min: 6,
              max: 20,
              message: "6到20个字符",
              trigger: "blur",
            }
          ]
        },
      };
    },
    computed: {
      account(){
        return this.params.account?.account
      },
      mobile(){
        return this.params.mobile?.mobile
      },
      headicon(){
          let path = this.params.wechat?.avatarUrl;
          if (path) {
            if (!isUrl(path)) {
                path = client.options.server + path;
            }
          }
        return path
      },
      avater(){
          let path = this.params.avater;
          if (path) {
            if (!isUrl(path)) {
                path = client.options.server + path;
            }
          }
        return path
      },
    },
    created() {
      let id = this.$route.params.id as string;
      this.params.id = parseInt(id);
        console.error(id,"跳转到编辑")
      if(id){
        this.detail();
      }
    },
    methods: {
      handleClick(tab: { index: string; }) {
        this.activeIndex = tab.index;
      },
      async onUploadChange(res: { raw: File,name:string }){
        let file = res.raw;
        let isSuss = this.beforeUpload(file);
        if(!isSuss){
          return
        }
        let fileData = await loadFile(file);
        let ret = await client.callApi("pub/UploadImage", {
            file: fileData,
            filename: file.name
        });
        if (ret.isSucc) {
          this.params.avater = ret.res.path;
        } else {
              ElMessage({
                message:ret.err.message,
                type: "error",
              });
        }
      },
      beforeUpload(rawFile:File) {
        let isJPG = rawFile.type === 'image/jpeg';
        let isPNG = rawFile.type === 'image/png';
        let isLt2M = rawFile.size / 1024 / 1024 < 2;
        if (!isJPG && !isPNG) {
            ElMessage({
              message:"只能上传 JPG 或 PNG 格式的图片",
              type: "error",
            });
          return false;
        }
        if (!isLt2M) {
            ElMessage({
              message:"图片大小不能超过 2MB",
              type: "error",
            });
          return false;
        }
        return true;
      },
      // 文章详情
      async detail() {
        try {
          let res = await client.callApi("user/Detail",{id:this.params.id});
          if (res.isSucc) {
            this.params = res.res;
          }
        } catch (error) {
          console.error(error);
        }
      },
      //更新
      async update() {
        let params:ReqUpdate = {
          ...this.params
        };
        let res = await client.callApi("user/Update",params);
        if (res.isSucc) {
            ElMessage({
                message: "更新成功^_^",
                type: "success",
            });
            this.$router.go(-1);
        }
      },
      //选择栏目
      handleChange(e: any):void {
        console.error(this.params.sex,e)
      },
      submit(formName: string) {
        let refs:any = this.$refs[formName];
        if (refs) {
            refs.validate((valid: any) => {
            if (valid) {
              this.update();
            } else {
              console.log("error submit!!");
              return false;
            }
          });
        }
      },
    },
  });
  